import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


//import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { Link } from 'react-router-dom';


const useStyles = makeStyles({
  card: {
    minWidth: 600,
    maxWidth: 900,
    background: '#DCD1EE',
    marginBottom: 20,
  },
  media: {
    height: 140
  },
});

export default function MediaCard({pic, link, title, subtitle, description, finished}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <Link to={link}>
        <CardMedia
          className={classes.media}
          image={pic}
        />
        <CardContent>
          <Typography variant="h4">
            {title}
          </Typography>
          <Typography gutterBottom variant="h5">
            {subtitle}
          </Typography>
          <Typography variant="h6">
            {description}
          </Typography>
        </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}
