import React from "react";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function WW() {
		return(
			<div className="project">
				<ScrollToTopOnMount />

			<div className="projectImgContainer">
				<img
					className="projectImg"
					src={process.env.PUBLIC_URL + "../images/ww_banner.png"}
					alt="ww logo"
				/>
			</div>

	        <h1>UX Research Internship at WW</h1>

					<div className="row">

						<div className="projectCol">

							<h2>Role</h2>
								<p>Experience Research Intern</p>

							<h2>Date</h2>
								<p>05/2019 - 08/2019</p>

							<h2>Duration</h2>
								<p>11 weeks</p>

						</div>

						<div className="projectCol">

						<h2>Responsibilities</h2>
							<ul>
								<li>Created research plan &amp; all study materials (e.g., recruitment
									criteria; interview guides; usability guides; diary study forms;
                  Mural.ly workspace)</li>
								<li>Assisted with in-person recruitment at WW studio locations</li>
								<li><b>Study 1:</b> Led & conducted usability testing &amp;
                structured interviews with over 8 current WW members</li>
                <li><b>Study 2:</b> Led & conducted a week-long Qualtrics diary
                study followed by structured exit interviews incorporating a
                Mural.ly workspace with 12 current WW members</li>
								<li>Completed research synthesis, created share-out slide decks,
								&amp; presented research findings to cross-functional teams</li>
							</ul>

            <h2>Outcomes</h2>
              <ul>
                <li><b>Study 1 (Interview + usability test)</b> helped to refine
                a feature released to 30,000 WW members in the Fall of 2019
                </li>
                <li><b>Study 2 (Diary study + interview)</b> informed a 5-week
                pilot study with 1,000 WW members in the Fall of 2019
                </li>
              </ul>

						</div>

						<div className="projectCol">

							<h2>Team</h2>
								<p>Experience Research team at WW HQ in San Francisco</p>

							<h2>Methodologies &amp; Tools</h2>
								<ul>
									<li>Remote user interviews via Zoom</li>
									<li>Remote synchronous usability testing via Validately &amp;
                  Zoom</li>
									<li>Diary study via Qualtrics</li>
									<li>Mixed methods user interviews incorporating Mural.ly
                  workspace</li>
								</ul>

						</div>

					</div>

					<h1>Study 1: Interview &amp; Usability Test*</h1>

					<p><b>*Descriptions of research activities are deliberately
					vague in order to maintain NDA-compliance &amp; project confidentiality.</b></p>

					<h2>Background &amp; Research Questions</h2>

					<p>For this first project, I conducted user research on a
					feature of the WW mobile app that had been tested by the design team,
					but had not yet been evaluated by a user researcher. Prior to the start
					of my internship, the design &amp;
					research teams had already discussed their research goals &amp; their
					plans to utilize a high-fidelity prototype for a usability test with
					current WW members.</p>

					<p>The <b>main objectives</b> of this research project were to better understand:</p>
						<ol>
							<li>the current usability of this feature of the WW mobile app.
							</li>

							<li>users' perception of the feature in relation to their overall WW experience.
							</li>

							<li>why many users appeared to be unaware of the feature based on app analytics.
							</li>
						</ol>

					<h2>Recruitment Guidelines</h2>

					<p>I worked with the design team to establish the recruitment criteria
					for this study.  We planned to enroll 8 participants, all of whom
					were current WW members. We made the following recruitment inclusion
          guidelines:
					</p>

          <br />

          <table className="noLines" width="900px" align="center">
            <tr>
              <th width="100px"></th>
              <th width="200px">Inclusion Guideline</th>
              <th width="600px">Rationale</th>
            </tr>

            <tr>
              <td>
                <img
        					className="icon"
        					src={process.env.PUBLIC_URL + "../images/WW/id.png"}
        					alt="icon of membership badge"
        				/>
              </td>

              <td>a) Members with a studio + digital app membership &amp; b)
  						members with a digital-only membership
              </td>

              <td>We assumed that studio members might learn of the feature through
              discussions in studio workshops, whereas members with a digital-only
              membership might not discover the feature at all, unless they
              find it during app exploration on their own.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/question.png"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              a) Members who had already visited the feature within the
  						app &amp; b) members who had not
              </td>

              <td>
              We were interested in understanding potential barriers to discovering
              the feature, which could be illuminated by speaking with members
              who had not yet visited it within the app.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/age.png"}
                  alt="icon of a young and old person"
                />
              </td>

              <td>
              a) At least 2 members below the age of 30 &amp; b) at least 2 members
              over the age of 55
              </td>

              <td>
              We wanted to ensure representation of 2 major demographics of
              WW members, and also hypothesized that age might be contributing to
              current use or disuse of the feature.
              </td>
            </tr>
          </table>


					<h2>Structured Interview</h2>

					<p>As the lead researcher on this project, I was responsible for
          crafting the interview &amp; usability guides, &amp; I conducted all
          research activities with participants via a Zoom call while other
          stakeholders (including the UXR manager, the designers of this feature,
          &amp; the PM) silently observed &amp; assisted with note-taking.
          </p>

					<p>After providing informed consent, the study began with a brief
          structured interview addressing the following topics:
					</p>

          <table className="noLines" width="900px" align="center">
            <tr>
              <th width="100px"></th>
              <th width="400px">Interview Topics</th>
              <th width="400px">Objective</th>
            </tr>

            <tr>
              <td>
                <img
        					className="icon"
        					src={process.env.PUBLIC_URL + "../images/WW/dems.png"}
        					alt="icon of membership badge"
        				/>
              </td>

              <td>Ask about demographic information
              </td>

              <td>Build rapport at the beginning of the interview &amp; better
              be able to understand &amp; contextualize the participant's
              unique personal experience
              </td>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/travel.png"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              Learn more about their WW Journey so far
              </td>

              <td>
              Continue building rapport &amp; potentially obtain information to
              assist in constructing user personas related to this feature
              </td>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/smartphone.png"}
                  alt="icon of a young and old person"
                />
              </td>

              <td>
              Ask about how they currently use the WW app
              </td>

              <td>
              Learn about their current usage habits &amp; their priorities when
              using the app
              </td>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/opinion.svg"}
                  alt="icon of a young and old person"
                />
              </td>

              <td>
              Probe for their familiarity with the feature &amp; their current perceptions
              of it
              </td>

              <td>
              Prior to showing them the updated prototype of the feature, assess their
              base understanding of it &amp; their personal opinions of it based
              on what they already know about it
              </td>
            </tr>
          </table>

					<h2>Usability Test</h2>

					<p>After the interview, while still communicating with the participant
          via video chat over Zoom, I shared with them a link to the high-fidelity
          InVision prototype of the revised feature. I asked them to share their
          screen while they performed each usability test task, &amp; instructed
          them on how to engage in a think-aloud protocol to actively share their
          thoughts along the way.</p>

          <p>I followed my structured usability task guide, interleaving
          probing follow-up questions as necessary between each major task.</p>

          <h2>Synthesis</h2>

          <p>For my synthesis process, I rewatched each interview recording while taking
          notes, extracting direct user quotes, &amp; creating video clips to include in my
          key findings presentation. I used a spreadsheet to organize this information,
          as well as to help in identifying patterns across participants.
          </p>

					<h2>Key Findings</h2>

					<p>Recalling the research goals of this project, which included 1) assessing
          the feature's usability; 2) understanding users' perceptions of it, &amp;
          3) uncovering why many users were unaware of it, I organized the key
          findings that arose after reviewing the interview &amp; usability test data:
          </p>

          <table className="noLines" width="1100px" align="center">
            <tr>
              <th width="100px"></th>
              <th width="200px">Key Finding</th>
              <th width="500px">Explanation</th>
              <th width="300px">Recommendation</th>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/eye.png"}
                  alt="icon of membership badge"
                />
              </td>

              <td>Small visual details were being missed that made the UX harder
  						to understand &amp; use
              </td>

              <td>A small snackbar with a status message, a brief transition explaining an
              unlocked card, &amp; a quick animation indicating an accomplishment were
              overlooked by the majority of participants, contributing to their confusion
              as they attempted to understand the purpose of this feature. This
              was especially apparent for older users (who may have age-related deficits
              in visual processing speed).
              </td>

              <td>Consider designing slower or more overt animations, perhaps in
              conjunction with providing additional text to guide the user's
              understanding of each facet of the feature to increase the overall
              accessibility of this information.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/question-mark.png"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              Overall, users reacted positively to the feature, but the "why"
              behind it was unclear
              </td>

              <td>
                Users expressed interest during exploration, but
                also vocalized new questions left unanswered by the prototype.
                These questions included: a) "Could this feature be personalized?"
                b) "Why were these particular parameters selected?" and c) "Would these
                parameters change in the future?" The resulting uncertainty
                motivated users to craft their own interpretations, at times
                resulting in poor comprehension &amp; misunderstandings about
                the purpose of the feature.
              </td>

              <td>Perhaps include an onboarding experience introducing the feature,
              so as to more explicitly explain its purpose &amp; establish
              user expectations.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/computer.png"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              Users liked the inclusion of a personal summary, but
              had difficulty using it to accomplish their goals
              </td>

              <td>
              One component of the feature was a summary of information
              related to a user's progress. When asked to articulate their understanding
              of the meaning of this summary information, it was uncovered that
              there was misalignment between how they expected to use this summary
              information, &amp; what was actually possible with the summary data
              given.
              </td>

              <td>Explore other ways of presenting this summary information that
              better facilitates the intended goals of the user when they interact
              with that information.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/ranking.png"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              This feature is not the top priority for some members
              </td>

              <td>
              While members already familiar with the feature noted its value,
              those that were unfamiliar &amp; felt confused during the usability test
              indicated that this feature might actually detract from their WW
              experience because it felt separate from their weight
              loss goals. This may help to explain why some members have not
              previously visited it, because they utilize the app to accomplish
              core tasks, &amp; do not explore the more peripheral features of the app.
              </td>

              <td>Ensure that this feature remains optional for members, so that those
              who benefit from it can use &amp; enjoy it, while those  that do not
              find the same value will not feel frustrated or distracted by it.
              </td>
            </tr>

            <br />

          </table>

          <h2>Final Steps</h2>

          <p>I prepared a slide deck communicating my research methodology &amp;
          key findings &amp; presented it to the designers & PM responsible for this
          feature.
          </p>












      </div>

		)
}
