import React, { Component } from "react";

class Contact extends Component {
	render() {
		return(
			<div>
				<div className="homeBody">

				<h1>contact</h1>

				<h2>
					Feel free to email me at ashten_bartz@berkeley.edu or view my
					LinkedIn <a href="https://www.linkedin.com/in/ashten-bartz/" target="_blank"
					rel="noopener noreferrer">here</a>.
				</h2>

				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />

				</div>
      </div>
		);
	}
}

export default Contact;
