import React from "react";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function Journey() {
		return(
			<div className="project">

				<ScrollToTopOnMount />

			<div className="projectImgContainer">
				<img
					className="projectImg"
					src={process.env.PUBLIC_URL + "../images/journey_banner.png"}
					alt="journey logo"
				/>
			</div>

	        <h1>Lead User Researcher for Journey</h1>

          <h2>About Journey</h2>
						<p>As part of a 3-person capstone team, I assisted in the development
						of a mental health mobile application for folks in therapy to use
						between their scheduled sessions. We interviewed patients &amp;
						therapists as our generative research activities, &amp; then
						conducted 3 rounds of usability testing with rapid prototyping in
						order to 1) select a core set of app features, 2) test comprehension
						in an end-to-end user flow, &amp; 3) assess the app’s success in
						accomplishing our design goals.</p>

						<div className="pptImg">
							<img
								className="pptImg"
								src={process.env.PUBLIC_URL + "../images/isometric-mockup.png"}
								alt="Generative research overview"
							/>
						</div>


					<div className="row">

						<div className="projectCol">

							<h2>Role</h2>
								<p>Lead User Researcher</p>

							<h2>Date</h2>
								<p>09/2019 - 05/2020</p>

							<h2>Duration</h2>
								<p>9 months</p>

						</div>

						<div className="projectCol">

						<h2>Responsibilities</h2>
							<ul>
								<li>Lead creation of the IRB protocol and all supplementary
                materials (recruitment materials, forms, guides, etc.)</li>
								<li>Lead research activities (recruit participants, conduct
                  interviews)</li>
								<li>Analyze the research findings & use them to inform the
                design of our team’s app</li>
								<li>Assist with wireframing & design iteration</li>
                <li>Facilitate usability testing on the app to refine & evaluate the design</li>
							</ul>

						<h2>Outcomes</h2>
							<ul>
								<li>Our generative research helped us identify our target user &amp;
								scope the core functionality of our app.
								</li>
								<li>Usability testing helped us identify comprehension problems &amp;
								brainstorm design solutions.
								</li>
								<li>Our final, hi-fidelity prototype received a System Usability
								Score (SUS) in the 90th percentile.
								</li>
							</ul>

						</div>

						<div className="projectCol">

							<h2>Team</h2>
								<ul>
                  <li>Brandon Shalchi (Lead User Researcher)</li>
                  <li>Victor Grajski (Lead Designer)</li>
                </ul>

							<h2>Methodologies &amp; Tools</h2>
								<ul>
                  <li>Literature reviews</li>
									<li>Generative remote user interviews with clients &amp; patients</li>
									<li>Usability testing</li>
                  <li>Card Sorting</li>
								</ul>

						</div>

					</div>

					<h2>The Research Process</h2>

					<p>To start, we conducted generative interviews with both patients &amp;
					therapists. We then conducted several iterations of design interleaved
					with usability testing. Our overall goals included identifying 1) who
					we wanted to design for, within our problem space of the inbetween
					therapy session experience 2) what their needs are and 3) validating
					through usability testing that our design met those needs.</p>

					<div className="pptImg">
						<img
							className="pptImg"
							src={process.env.PUBLIC_URL + "../images/research.png"}
							alt="Research overview"
						/>
					</div>

					<br />

					<h2>Generative Research Key Findings</h2>

					<p>We interviewed 8 patients and 6 therapists using an IRB-approved,
					semi-structured interview guide.
					</p>

					<div className="pptImg">
						<img
							className="pptImg"
							src={process.env.PUBLIC_URL + "../images/generative.png"}
							alt="Generative research overview"
						/>
					</div>

					<ul>
						<li>
					Therapists highlighted the therapeutic alliance. They value the
					relationships with their clients &amp; see it as their job to maintain
					&amp; strengthen them, &amp; a digital system should do the same,
					not aim to replace or undermine this relationship.
						</li>

						<li>
					Another emergent theme was how patients often guide in-session topics.
					This isn’t always the case since there are different types of
					psychotherapy, but we gravitated towards this more humanistic
					approach because suggestions between therapy sessions mirrored a
					user-centered, client-directed plan of action.
						</li>

						<li>
					During the intersession period, the saliency of emotions &amp;
					thoughts guide when, what, &amp; how patients write, as well as whether
					or not they choose to save or throw away those entries. Patients
					journaled briefly on their phone when out &amp; about, but primarily
					used a paper journal when at home.
					Digital entries reminded them of a topic to discuss in therapy or to
					journal more at length at home, however, keeping track of these entries
					that are often saved in many different places left patients feeling
					lost &amp; disorganized.
					</li>

					<li>
					Additionally, patients wanted affirmations about their progress during
					the intersession period, and often seek this from therapists, friends, &amp;
					family because it’s hard for them to see it themselves.
					</li>

					</ul>

					<br />

					<h2>Lo-Fi Design Usability Test Key Findings</h2>

					<p>Using the key findings from the interviews to inform our first
					round of low-fidelity prototypes, we conducted 1-hour usability tests
					to explore 4 potential directions the app could take.
					</p>

					<div className="pptImg">
						<img
							className="pptImg"
							src={process.env.PUBLIC_URL + "../images/lofi.png"}
							alt="Generative research overview"
						/>
					</div>

					<p>The 4 features included:</p>

					<ul>
						<li>
						Containerization, which explored the ability to delete an entry
						after compartmentalizing troublesome thoughts or feelings;
						</li>

						<li>
						Introspectre, which encourages several different types of reflection;
						</li>

						<li>
						Affirmations, which allows a patient to reach out to their therapist
						for additional support;
						</li>

						<li>
						And Toolkit Builder, which utilized a community for sharing
						therapeutic skills.
						</li>
					</ul>

					Based on our usability test findings, we learned:

					<ul>

						<li>
						Some features, like Affirmations, felt redundant and even inferior
						to existing technology. As one participant said, “why would I use a
						third party app to contact my therapist? I would just text them
						instead.”
						</li>

						<li>
						Containerization felt too niche to be applicable to our general
						target user;
						</li>

						<li>
						And connecting users with a virtual community raised questions about
						where data was being stored and concerns about the privacy of their
						journal entries.</li>

						<li>
						We learned that users wanted a non-paternalistic tool that didn’t
						require specific interactions at specific times, but rather provided
						lightweight structure for a variety of use cases.
						</li>
					</ul>

					<br />

					<h2>Mid-Fi Design Usability Test Key Findings</h2>

					<p>We now had a better understanding of our target user as someone
					engaged in continuing the therapeutic journey outside of session, &amp;
					developed a persona focused on their key needs: the ability to organize
					their thoughts and feelings, especially in the context of immediately
					before &amp; after therapy sessions, as well as a way of making
					sense of their ongoing progress across sessions.</p>

					<p>We conducted a second round of testing on an end-to-end user
					flow to assess comprehension &amp; identify usability problems. We
					also included a card sort activity to help inform the app’s
					information architecture.
					</p>

					<div className="pptImg">
						<img
							className="pptImg"
							src={process.env.PUBLIC_URL + "../images/midfi.png"}
							alt="Generative research overview"
						/>
					</div>

					<p>This prototype included:</p>

					<ul>
						<li>
							A daily homepage
						</li>

						<li>
							The ability to add a new entry &amp; tag related themes &amp; feelings,
						</li>

						<li>
							An archive containing past entries from between sessions, as well
							as notes from before &amp; after a therapy session,
						</li>

						<li>
							And personal trends visualizing tagged themes &amp; feelings over time.
						</li>
					</ul>

					<p>
					Our second round of testing:</p>

					<ul>
						<li>
							Encouraged us to simplify the homepage,
						</li>

						<li>
							As well as to isolate previous entries within the archive, because
							while sometimes it is beneficial to review old content, at other
							times users want to look to the future rather than to the past
						</li>

						<li>
						Participants found value in the ability to add tags, because this
						would help them to track patterns &amp; prioritize recurrent topics
						in their next therapy session.
						</li>

						<li>
						They also appreciated the opportunity to view their personal trends,
						as this could assist in visualizing their progress, an otherwise
						largely abstract concept in therapy.
						</li>
					</ul>

					<br />

					<h2>Hi-Fi Design Usability Test Key Findings</h2>

					<p>From these findings, we created our high-fidelity prototype, &amp;
					lastly, we conducted a final round of usability testing to evaluate the
					success of our app in helping to encourage introspection between
					therapy sessions with our target user in mind.</p>

					<p>
					Our usability tests at this stage further affirmed we were meeting the
					needs we uncovered in our generative interviews. Our participants
					described the tone of the app as “comfortable”, “non-judgemental”,
					&amp; “calming”, which validates our intentional design decisions
					around layout, typography, &amp; color to provide a neutral space
					where they may reflect on their thoughts &amp; feelings. Our interface
					revisions resolved previous usability issues, &amp; our final prototype
					received a System Usability Scale score in the 90th percentile.
					</p>

					<div className="pptImg">
						<img
							className="pptImg"
							src={process.env.PUBLIC_URL + "../images/hifi.png"}
							alt="Generative research overview"
						/>
					</div>

					<p>One area that stood out in particular was how we made our target
					user’s therapy progress feel more tangible.
					</p>

					<div className="row">

					<div className="gifImg">
					<h2>Add an Entry</h2>
					<br />
						<img
							className="gif"
							src={process.env.PUBLIC_URL + "../images/add.gif"}
							alt="Add an entry gif"
						/>
					<p>Users appreciated that Journey maintained the freedom of
					a pen-and-paper journal while incorporating thematic &amp; emotional
					tagging to surface patterns that would be difficult to find otherwise.
					</p>

					</div>

					<div className="gifImg">
					<h2>Before &amp; After</h2>
					<br />
						<img
							className="gif"
							src={process.env.PUBLIC_URL + "../images/before.gif"}
							alt="Before and after gif"
						/>
					<p>The ability to prioritize what to talk about in their next session
					&amp; save key takeaways afterwards helps facilitate continuity from
					session to session.</p>

					</div>

					<div className="gifImg">
					<h2>Find Entries</h2>
					<br />
						<img
							className="gif"
							src={process.env.PUBLIC_URL + "../images/filter.gif"}
							alt="Filter entries gif"
						/>
					<p>The search &amp; filter functionality augments how users may
					reflect on their past as it relates to their present.</p>

					</div>

						<div className="gifImg">
						<h2>Review Trends</h2>
						<br />
							<img
								className="gif"
								src={process.env.PUBLIC_URL + "../images/trends.gif"}
								alt="View trends gif"
							/>
						<p>Our participants found Trends to be the most valuable feature of
						the app because it facilitates goal setting, inspires discussion
						topics for therapy, and helps them visualizes measurable changes
						over time</p>
						</div>


					</div>

					<h2>Next Steps</h2>

					<p>We look forward to continuing working on Journey by beginning
					development of the app in Summer of 2020.
					</p>



      </div>
		);
	}
