import React, { Component } from "react";

class About extends Component {
	render() {
		return(
			<div className="aboutBody">

				<div className="row">

					<div className="aboutCol">

					<h1>about me</h1>

				<h2>I'm a <span className="stylized">recent master's graduate from
				the UC Berkeley School of Information</span>, but I'd also describe myself
				as a cognitive scientist, a sleep technologist, a creative writer, a
				wellness enthusiast, a human-centered designer, a mental health advocate,
				an innovator for social good, & an intersectional feminist.

				<br /> <br />

				My fascination with human consciousness guided my undergraduate studies
				in <span className="stylized">cognitive science at Vassar
				College</span> before centering on sleep research during my five years at
				a <span className="stylized">sleep & chronobiology research lab at Brown
				University</span>.

				<br /> <br />

				Sleep is intimately related with nearly every facet of successful waking
				life functioning, &amp; yet as a society, we value productivity &amp;
				sleeplessness at the expense of our health &amp; happiness.

				<br /> <br />

				While at the iSchool, I focused on user
				experience research & design as well as entrepreneurship. Within the iSchool
				community, I served as the Information Management Student Association's
				Wellness Chair.

				<br /> <br />

				While still deeply passionate about sleep, I've been broadening my research
				interests to the larger health &amp; wellness space by working on UX research
				projects with companies & startups also focusing on
				<span className="stylized">mental health, women's
				health, &amp; lifestyle wellness</span>.

				<br /> <br />

				I aspire to work at the intersection of health &amp;
				information technologies doing user research for a company committed to
				accomplishing social good through contributing to positive wellness
				outcomes for all.</h2>

				</div>

				<div className="aboutPix">

				<br />

				<div className="aboutImgContainer">
					<img
						className="aboutImg"
						src={process.env.PUBLIC_URL + "../images/bartz_headshot.jpg"}
						alt="headshot"
					/>
				</div>

				<br />

				<div className="aboutImgContainer">
					<img
						className="aboutImg"
						src={process.env.PUBLIC_URL + "../images/vassar.png"}
						alt="vassar logo"
					/>
				</div>

				<div className="aboutImgContainer">
					<img
						className="aboutImg"
						src={process.env.PUBLIC_URL + "../images/brown.jpg"}
						alt="brown logo"
					/>
				</div>

				<div className="aboutImgContainer">
					<img
						className="aboutImg"
						src={process.env.PUBLIC_URL + "../images/berkeley.jpg"}
						alt="berkeley logo"
					/>
				</div>

				<br />

				<br />

				</div>

				</div>
      </div>
		);
	}
}

export default About;
