import React from "react";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function Sleep() {
		return(
			<div className="project">

				<ScrollToTopOnMount />

			<div className="projectImgContainer">
				<img
					className="projectImg"
					src={process.env.PUBLIC_URL + "../images/sleep_banner.png"}
					alt="sleep health logo"
				/>
			</div>

	        <h1>Senior Research Technologist at SFS Research Lab</h1>

          <h2>About SFS Research Lab</h2>
						<p>The E.P. Bradley Hospital Sleep for Science Research Lab is a
            Brown University-affiliated, internationally-recognized center for
            research on sleep &amp; development, directed by Mary A. Carskadon,
            PhD. Read more about the lab <a href="https://www.sleepforscience.org/" target="_blank"
            rel="noopener noreferrer">here</a>.</p>


					<div className="row">

						<div className="projectCol">

							<h2>Role</h2>
								<p>Senior Research Technologist</p>

							<h2>Date</h2>
								<p>08/2015 - 08/2018</p>

							<h2>Duration</h2>
								<p>3 years</p>

						</div>

						<div className="projectCol">

						<h2>Responsibilities</h2>
							<ul>
								<li>Directed & managed a team of 15 individuals for NIH-funded
                research studies within time & budget constraints.</li>
                <li>Collaborated with research team leadership to design &
                optimize in-lab research protocols.</li>
								<li>Facilitated data management tasks including data entry,
                cleaning, & coding using SPSS syntax & Qualtrics. </li>
								<li>Recruited, screened, scheduled, & enrolled participants
                while managing the participant database & study calendar.</li>
                <li>Assisted with IRB.net submissions as well as HIPAA &amp;
                CITI compliance for all lab personnel.</li>
							</ul>

						</div>

						<div className="projectCol">

							<h2>Team</h2>
								<ul>
                  <li>SFS Research Lab team</li>
                </ul>

							<h2>Methodologies &amp; Tools</h2>
								<ul>
                  <li>Surveys</li>
									<li>Data management (SPSS)</li>
                  <li>IRB.net</li>
									<li>Filemaker</li>
								</ul>

						</div>

					</div>


      </div>
		);
	}
