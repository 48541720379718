import React from "react";
import MediaCard from './MediaCard';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function ProjectGrid ({source}) {
  const classes = useStyles();


    return (
      <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} justify="center" alignItems="center">
          {source.map(card => (
            <MediaCard
              key={card.id++}
              pic={card.pic}
              link={card.link}
              title={card.title}
              subtitle={card.subtitle}
              description={card.description}
            />
          ))}
        </Grid>
      </Grid>
      </div>
    );
  };
