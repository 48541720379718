import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';


export default function Header() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <div>
        <div className="homeName">
          ashten l. bartz
        </div>

    <div>
    <AppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor=""
        textColor="primary"
        centered
        className="navBar"
      >
        <Link to="/"><Tab label="home" /></Link>
        <Link to ="/about"><Tab label="about" /></Link>
        <Link to="/contact"><Tab label="contact" /></Link>
      </Tabs>
    </AppBar>
    </div>
  </div>
  );
}
