import React, { Component } from "react";

class Footer extends Component {
	render() {
		return(
			<footer>
        <h3>Ashten Bartz &reg; 2018 - 2020</h3>
      </footer>
		);
	}
}

export default Footer;
