import React, { Component } from "react";
import ProjectGrid from './ProjectGrid';
import data from './data.json';


class Home extends Component {
	render() {
		return(
			<div>

				<div className="homeBody">

					<h1>hi, i'm ashten.</h1>

					<h2>
						I'm a <span className="stylized"> user experience
          	researcher</span> passionate about the health
						&amp; wellness space with
						7 years of experience conducting behavioral science research & a
						background in cognitive science. I am
						also a recent master's graduate from the <span className="stylized">School
						of Information at UC Berkeley</span>, and am actively seeking
						UX research positions.

				 	</h2>


          <h1>professional experience</h1>
        </div>

        <div className="centered">
          <ProjectGrid source={data}/>
        </div>

			</div>
		);
	}
}

export default Home;
