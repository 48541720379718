import React from "react";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function Viva() {
		return(
			<div className="project">
				<ScrollToTopOnMount />

			<div className="projectImgContainer">
				<img
					className="projectImg"
					src={process.env.PUBLIC_URL + "../images/viva_banner.png"}
					alt="viva logo"
				/>
			</div>

	        <h1>Lead Researcher, Designer, &amp; Developer at Viva</h1>

          <h2>About Viva</h2>
						<p>Viva is a female-founded social enterprise that supports
            lifestyle wellness. Born out of UC Berkeley, we are intent on
            changing the way we engage with well-being. Viva
            combines in-person workshops
            &amp; experiences with digital mindfulness tools. Learn
            more <a href="https://www.thevivaway.com/" target="_blank"
            rel="noopener noreferrer">here</a>.</p>

            <p>Viva was awarded <a href="https://bigideascontest.org/projects/viva-3rd-place/" target="_blank"
            rel="noopener noreferrer">3rd place</a> in the 2019 Big Ideas competition for
            the Connected Communities category.</p>


					<div className="row">

						<div className="projectCol">

							<h2>Role</h2>
								<p>Lead UX Researcher, Designer &amp; Developer; CTO; Co-Founder</p>

							<h2>Date</h2>
								<p>12/2018 - 05/2020</p>

							<h2>Duration</h2>
								<p>1.5 years</p>

						</div>

						<div className="projectCol">

						<h2>Responsibilities</h2>
							<ul>
								<li>Oversee all data activities (collection, management,
                  analysis, &amp; visualization)</li>
								<li>Design &amp; conduct all qualitative &amp; quantitative user
                experience research activities</li>
								<li>Produce wireframes &amp; conduct rapid iterative prototyping
                incorporating
                research findings &amp; design thinking.</li>
								<li>Develop &amp; maintain full-stack web &amp; mobile products.</li>
							</ul>

              <h2>User Research Artifacts</h2>
              <p>Please email me for redacted user research artifacts, or to learn more
              about the research methodologies used thus far.</p>

						</div>

						<div className="projectCol">

							<h2>Team</h2>
								<p>Catherine Soler (CEO &amp; Co-Founder)</p>

							<h2>Methodologies &amp; Tools</h2>
								<ul>
                  <li>Focus groups</li>
									<li>Remote user interviews via phone</li>
                  <li>Design prototyping using Balsamiq, Figma &amp; Canva</li>
									<li>Lo-fi prototype usability testing</li>
                  <li>Adoption &amp; attrition user analyses</li>
									<li>Quantitative surveys using Google Forms</li>
								</ul>

						</div>

					</div>

      </div>
		);
	}
