import React from "react";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function Fovi() {
		return(
			<div className="project">

				<ScrollToTopOnMount />

			<div className="projectImgContainer">
				<img
					className="projectImg"
					src={process.env.PUBLIC_URL + "../images/fovi_banner.png"}
					alt="fovi health logo"
				/>
			</div>

	        <h1>User Researcher for Fovi Health</h1>

          <h2>About Fovi Health</h2>
						<p>While most pregnancy apps &amp; educational resources are focused on
            providing support to women during their pregnancies, Fovi Health is
            a text-message service helping new moms navigate the postpartum period.
            Our task was to help conduct user research to inform
            design decisions as the client worked to expand their SMS service to
            a full mobile application. Learn
            more about Fovi Health <a href="https://www.fovihealth.com/" target="_blank"
            rel="noopener noreferrer">here</a>.</p>


					<div className="row">

						<div className="projectCol">

							<h2>Role</h2>
								<p>User Researcher</p>

							<h2>Date</h2>
								<p>01/2019 - 05/2019</p>

							<h2>Duration</h2>
								<p>5 months</p>

						</div>

						<div className="projectCol">

						<h2>Responsibilities</h2>
							<ul>
								<li>Creation of all research
                materials (recruitment flyers, screener forms, interview
                guides, etc.)</li>
								<li>Facilitate, note-take, &amp; transcribe user interviews </li>
								<li>Create materials for remotely conducting features assessment
                &amp; reaction card method using Google Slides</li>
								<li>Synthesize all research findings in a slide deck &amp; an
                executive report for the client</li>
							</ul>

            <h2>Outcomes</h2>
              <ul>
                <li>Provided client with guidance on users' preferred voice &amp; tone
                of the app copy, helping to establish clear branding guidelines
                </li>

                <li>Advised client on app feature prioritization in preparation for mobile
                app development
                </li>

              </ul>

						</div>

						<div className="projectCol">

							<h2>Team</h2>
								<ul>
                  <li>Jessica Lee (UXR)</li>
                  <li>Jihea Moon (UXR)</li>
                  <li>Cindy Zhu (UXR)</li>
                </ul>

							<h2>Methodologies &amp; Tools</h2>
								<ul>
                  <li>Competitive analysis</li>
									<li>Remote user interviews via phone</li>
									<li>Features Assessment</li>
                  <li>Reaction Card Method</li>
								</ul>

						</div>

					</div>

          <h2>Background &amp; Research Questions</h2>

          <p>At the time of conducting this research project with our client, Fovi
          Health, they did not yet have branding nor tone guidelines for their
          products. As such, our first research goal was to help them to
          identify a tone for sharing the important, but deeply personal postpartum
          support &amp; information Fovi Health provides.</p>

          <p>Additionally, our client was interested in expanding their user base
          by being more accessible to groups of underserved mothers from diverse
          backgrounds. To assist in this objective, our research goal was to
          learn from diverse mothers about potential cultural nuances regarding
          their postpartum experiences, to better inform the creation of a mobile
          app that is accessible to all mothers. In particular, our client was
          interested in learning from a) Spanish-speaking mothers, b) African-American
          &amp; Black mothers; c) single mothers; d) low-income mothers;
          e) non-heterosexual mothers; &amp; f) non-binary &amp; gender non-conforming
          mothers/parents.</p>

          <p>Our research questions were:</p>

          <table className="noLines" width="900px" align="center">
            <tr>
              <th width="100px"></th>
              <th width="400px">Research Questions</th>
              <th width="400px">Assumptions</th>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/privacy.svg"}
                  alt="icon of membership badge"
                />
              </td>

              <td>How can we make postpartum moms feel comfortable asking questions
              &amp; seeking answers related to their physical &amp; mental
              postpartum challenges?
              </td>

              <td>Some women are embarrassed asking questions about "taboo"
              postpartum topics like sexual dysfunction, bodily changes, &amp;
              vaginal health. These users in particular need to feel safe &amp;
              that they are not being judged when seeking answers.
              </td>

            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/identity.svg"}
                  alt="icon of membership badge"
                />
              </td>

              <td>How might postpartum challenges differ by identity, including
              race, ethnicity, socio-economic status, culture, gender, &amp;
              sexuality?
              </td>

              <td>The intersectionality of a mother's identities will create for
              unique postpartum information &amp; support needs, as opposed to
              a one-size-fits-all solution.
              </td>

            </tr>

          </table>

          <h2>Recruitment Strategy</h2>

            <p>In order to learn more about the unique experiences of mothers from
            many different types of backgrounds, we prioritized recruiting a diverse
            sample for our user interviews. We reached out to community groups,
            Meetup groups, &amp; Facebook groups specific to particular
            communities of mothers (e.g., the "Black
            Moms of San Francisco" group) to obtain permission to share our
            recruitment flyer with group members.</p>

            <p>Interested mothers were directed to an online form collecting
            demographic information (to help ensure we prioritized enrolling
            women from underserved communities) as well as to confirm that they
            had given birth within the last year, so as to ensure participants
            could still accurately recall their postpartum experiences from memory.
            </p>

          <h2>Competitive Analysis</h2>

          <p>While recruiting &amp; screening mothers for eligibility to participate
          in the study, we simultaneously conducted a competitive analysis with
          the goal of better understanding the existing industry space, as well as
          to help us acquire the domain knowledge necessary to better outline
          our interview guide, reaction card method, &amp; features assessment
          research activities.</p>

          <p>Because there weren't any direct competitors at the time, we
          selected secondary competitors as well as surrogate products with
          features similar to those that might be provided by Fovi Health.</p>

          <p>We selected 3 apps from each app category (pregnancy apps, therapy
          apps, &amp; menstrual cycle apps) &amp; assessed them on strengths,
          weaknesses, customer base, functionality, unique features, &amp;
          app store rating.</p>

          <h2>User Interviews, Features Assessment, &amp; Reaction Card Method</h2>

          <p>All studies were conducted via a phone call with each participant,
          as we designed the interview protocol with the assumption that a phone
          call (rather than a video call or an in-person meeting) would help the
          participant to feel more secure &amp; perhaps more anonymous, allowing
          them to be more candid in answering our questions about a very
          personal topic. Each call had one speaking interviewer &amp; one
          silent note-taker.</p>

					<p>After providing informed consent &amp; building rapport
          with casual questions, the interviewer went through
          the following structured interview topics. For topics 3 &amp; 4, I advised
          our team to create a Google Slides document that we would ask
          participants to open during the interview, which we had sent them (but asked
          them not to open until instructed to do so) prior to the interview.
          The deck included several slides with visual information
          for each related question (including a list of
          potential app features, an abridged list of reaction card words,
          &amp; images of screen captures from 2 secondary competitor apps). This
          was so that they could directly reference this resource while providing
          their answers, which was important in helping to minimize cognitive load
          or reliance on memory, which would be necessary if we read aloud a
          list of 20 reaction card words &amp; then asked the participant to
          select words that best answered our question from memory. Instead,
          they could simply view the list of words themselves during that part
          of our remote interview.
					</p>

          <table className="noLines" width="900px" align="center">
            <tr>
              <th width="100px"></th>
              <th width="400px">Interview Topics</th>
              <th width="400px">Objective</th>
            </tr>

            <tr>
              <td>
                <img
        					className="icon"
        					src={process.env.PUBLIC_URL + "../images/Fovi/book.svg"}
        					alt="icon of membership badge"
        				/>
              </td>

              <td>Ask about the mother's sources of postpartum information
              &amp; support
              </td>

              <td>Understand how mothers sought out answers to intimate postpartum
              questions in the absence of a postpartum support app like Fovi Health
              </td>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/problem.svg"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              Learn more about a mother's unique post-birth challenges (including
              physical, emotional, social, &amp; financial difficulties)
              </td>

              <td>
              Identify ways in which postpartum concerns may differ by
              intersectional identity (e.g., race, ethnicity, socio-economic status,
              culture, gender identity, &amp; sexual orientation)
              </td>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/WW/ranking.png"}
                  alt="icon of a young and old person"
                />
              </td>

              <td>
              <i>Features Assessment:</i> Ask participants to view a list of 9
              potential Fovi Health app features &amp; rank their top 5 choices
              </td>

              <td>
              Understand which features seem most important to mothers after
              reflecting on their own personal postpartum challenges
              </td>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/number.svg"}
                  alt="icon of a young and old person"
                />
              </td>

              <td>
              <i>Reaction Card Method:</i> Ask participants to view a list of
              25 words &amp; select the 5 that best describe a) their ideal Fovi
              Health app experience &amp; b) their impressions of 2 secondary
              competitor apps
              </td>

              <td>
              Imagine the ideal tone &amp; feeling users would want while interacting
              with the Fovi Health app &amp; understand their tone preferences
              by evaluating 2 existing apps in the market landscape
              </td>
            </tr>
          </table>

          <p>For the Features Assessment, we provided participants with a list of
          9 potential features identified through reviewing existing apps during
          our competitive analysis. For the Reaction Words
          task, we selected a subset of 25 words from the Microsoft
          Desirability Toolkit (Nielsen Norman Group, 2016). For the subtask
          in which we asked them to identify the top 5 words describing their ideal
          Fovi Health experience, we provided a list of 25 positive words, &amp;
          when asked to select the top 5 words that described their experience
          of viewing screencaps of the 2 secondary competitor apps,
          participants were provided a list of 25 words including an equal numbers
          of words with positive, negative, &amp; neutral affect or connotation.
          These competitor apps (WebMD
          Pregnancy &amp; Stop, Breathe, Think) were identified by the client as
          2 potential directions the Fovi Health tone &amp; branding could emulate.</p>

          <h2>Synthesis</h2>

          <p>
          Each group member listened to each interview recording &amp; worked collaboratively
          on updating that participant's notes file. As a group, we discussed
          the patterns that emerged during synthesis. Additionally, I was
          responsible for creating charts to help visualize the findings for the
          Feature Assessment &amp; Reaction Card tasks. </p>

          <p>Each participant ranked her top 5 features with a position (1-5),
          which were scored by rank order by assigning her #1 feature 5 points,
          her #2 feature 4 points, &amp; so on. These results are plotted showing
          the rank order feature score beside the number of votes (to help contextualize
          which features may have scored high because a few users ranked them very
          highly, versus features that may not have been participants' top choice,
          but were unanimously included in everyone's top 5 selected features).
          </p>

          <h3 align="center">Figure 1. Feature Assessment results (n=6)</h3>

          <div className="pptImg">
            <img
              className="pptImg"
              src={process.env.PUBLIC_URL + "../images/Fovi/rank.png"}
              alt="Generative research overview"
            />
          </div>

          <p>The Reaction Card charts illustrate the number of participants who
          selected a particular word as one of her top 5 words (from the list
          of 25 words provided) that best described that experience.
          </p>

          <h3 align="center">Figure 2. Reaction Cards results for the ideal imagined Fovi Health app experience (n=6)</h3>

          <div className="pptImg">
            <img
              className="pptImg"
              src={process.env.PUBLIC_URL + "../images/Fovi/ideal.png"}
              alt="Generative research overview"
            />
          </div>

          <h3 align="center">Figure 3. Reaction Cards results for WebMD Pregnancy app experience (n=6)</h3>

          <div className="pptImg">
            <img
              className="pptImg"
              src={process.env.PUBLIC_URL + "../images/Fovi/webmd.png"}
              alt="Generative research overview"
            />
          </div>

          <h3 align="center">Figure 4. Reaction Cards results for Stop, Breathe, Think app experience (n=6)</h3>

          <div className="pptImg">
            <img
              className="pptImg"
              src={process.env.PUBLIC_URL + "../images/Fovi/sbt.png"}
              alt="Generative research overview"
            />
          </div>

          <h3 align="center">Figure 5. Tone preference results favoring the friendly tone as compared to the clinical tone (n=6)</h3>

          <div className="chart">
            <img
              className="chart"
              src={process.env.PUBLIC_URL + "../images/Fovi/tone.png"}
              alt="Generative research overview"
            />
          </div>

          <h2>Key Findings</h2>

          <p>The key findings we identified include:
					</p>

          <table className="noLines" width="1100px" align="center">
            <tr>
              <th width="100px"></th>
              <th width="200px">Key Finding</th>
              <th width="500px">Explanation</th>
              <th width="300px">Recommendation</th>
            </tr>

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/isolation.svg"}
                  alt="icon of membership badge"
                />
              </td>

              <td>Many mothers expressed feeling socially isolated during the
              postpartum period due to a variety of reasons.
              </td>

              <td>They expressed wanting to find small, tight-knit communities (e.g.,
              people of color, moms with infants of similar ages, local moms in their
              neighborhood) to address individual needs &amp; identities. Participants
              felt more comfortable trusting digital connections with people in their
              community &amp; felt more likely to share information with peers if they
              felt that recipients would understand their circumstances.
              </td>

              <td>Facilitate creating small, close-knit communities, as mothers
              expressed that meeting other moms &amp; joining supportive groups
              helped them overcome challenges, including postpartum depression.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/check.svg"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              Participants were most interested in receiving answers to specific
              questions &amp; checking in with themselves.
              </td>

              <td>
              These desired features included the ability to ask their questions anonymously, complete a
              daily check-in survey about themselves, or receive a check-in from a
              trained Fovi Health coach. In contrast, they were less interested in
              an app that provides information
              specific to each postpartum milestone, or an app that incorporates
              postpartum exercising videos.
              </td>

              <td>Prioritizing features that help users check in mentally &amp;
              physically, as well as obtain pertinent, personalized information,
              would help provide them with encouragement &amp; reassurance
              about where they are in their postpartum journey.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/empower.svg"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              Users would want the app to feel "empowering," "empathic," "intuitive,"
              &amp; "supportive".
              </td>

              <td>
              These descriptors were more important to participants than other words
              that received 0 votes, such as "fun" or "enthusiastic," prioritizing
              their desire to be uplifted & supported by the app, rather than amused
              or entertained by it.
              </td>

              <td>Empower users by helping to normalize their experiences (both
                the good &amp; the bad!) by providing supportive realism
                rather than strictly cheerful optimism.
              </td>
            </tr>

            <br />

            <tr>
              <td>
                <img
                  className="icon"
                  src={process.env.PUBLIC_URL + "../images/Fovi/voice.svg"}
                  alt="icon of a question mark"
                />
              </td>

              <td>
              While users want trusted information, they prefer an approachable tone.
              </td>

              <td>
              Participants described WebMD Pregnancy as "clinical" &amp; "impersonal,"
              in contrast to describing Stop, Breathe, Think as "approachable" &amp;
              "friendly". This group of participants clearly favored the latter
              tone, with 80% indicating that they preferred it to the voice of
              WebMD Pregnancy.
              </td>

              <td>Prioritize providing trustworthy content, but deliver it in
              an accessible, friendly tone rather than an impersonal, formal one.
              </td>
            </tr>

          </table>

          <h2>Next Steps</h2>

          <p>Unfortunately due to challenges with recruiting, we were only
          able to complete research activities with 6 mothers, &amp; they were
          not as demographically diverse as we had intended. Lower enrollment may have
          been in part because of the sensitive nature of this research area, as
          well as difficulties in accessing our target groups via the recruitment
          methods we utilized. In the future, it would be critical to further
          explore the experiences of diverse groups of mothers to better understand
          the ways in which intersectional identities contribute to unique postpartum
          experiences.</p>

          <p>As a future research activity, we suggest conducting diary studies
          to complement the qualitative data obtained from our interviews.
          Diary studies could help to mitigate the potential memory issues
          that occur in recollecting past experiences during an interview, as the
          diary data would instead be collected <i>in situ</i> during the
          postpartum period. As such, these data may help to provide
          direction for more contextualized app features &amp; solutions. Lastly,
          self-reporting postpartum experiences via a diary form may increase
          a respondent's candor, as they may feel less vulnerable in
          honestly recounting their postpartum experience in a form,
          as compared to sharing those intimate details directly with a
          researcher during an interview.</p>

          <p>Lastly, we suggest conducting usability tests with low-fi prototypes
          in the future when the app reaches that stage of development, especially
          to help ensure that the tone &amp; branding recommendations we provided
          still resonate with mothers. Because we did not yet have access to prototypes,
          we asked mothers to hypothesize the words that best described their ideal
          Fovi Health experience, but it is important to reassess these
          speculations in the context of an actual app at a future date.</p>




      </div>
		);
	}
