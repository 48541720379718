import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


import './App.css';

import Header from './components/Header';
import Home from './components/Home';
import WW from './components/WW';
import Viva from './components/Viva';
import Journey from './components/Journey';
import Fovi from './components/Fovi';
import Sleep from './components/Sleep';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';


const specialElite = {
  fontFamily: 'Special Elite',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Special Elite'),
    local('Special Elite-Regular'),
  `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9E78E3',
    },
    secondary: {
      main: '#6F43A3',
    },
  },
  typography: {
    h2: {
      fontFamily: 'Special Elite',
      fontSize: 25,
    },
    h4: {
      fontFamily: 'Special Elite',
      fontSize: 24,
    },
    h5: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: 20,
    },
    h6: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: 16,
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [specialElite],
      },
    },
  },
});


function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Header />
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route path="/about" component={About}/>
              <Route path="/contact" component={Contact}/>
              <Route path="/ww" component={WW}/>
              <Route path="/viva" component={Viva}/>
              <Route path="/journey" component={Journey}/>
              <Route path="/fovi" component={Fovi}/>
              <Route path="/sleep" component={Sleep}/>
            </Switch>
          <Footer />
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
